import tw, { styled, theme } from 'twin.macro';

export const Wrapper = styled.input`
  ${tw`border-none bg-transparent placeholder-gray outline-none font-bold text-indigo text-2xl md:text-4xl leading-snug`}

  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme`colors.indigo`};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
