import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import { StyledForm, StyledFieldset } from './styled';
import Button from '@components/Button';
import Content from '@components/Content';
import { Context } from '@components/ContextWrapper';

interface FormProps {
  buttonLabel: string;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  company: string;
}

const Form: React.FC<FormProps> = ({ buttonLabel }) => {
  const { register, handleSubmit } = useForm();
  const [sending, setSending] = useState<boolean>();
  const [status, setStatus] = useState<number>();
  const { contactModalOpen } = React.useContext(Context);

  const encode = (data: Record<string, string>) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const onSubmit = async (data: FormData) => {
    setSending(true);

    const response = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data,
      }),
    });

    setStatus(response.status);
    setSending(false);
  };

  useEffect(() => {
    if (!contactModalOpen) setStatus(null);
  }, [contactModalOpen]);

  return (
    <StyledForm data-netlify="true" onSubmit={handleSubmit(onSubmit)}>
      {!sending && (
        <>
          <StyledFieldset>
            <Input
              type="string"
              ref={register}
              name="name"
              placeholder="Name and Surname"
              required={true}
            />
            <Input type="email" ref={register} name="email" placeholder="E-mail" required={true} />
            <Input type="tel" ref={register} name="phone" placeholder="Phone" required={true} />
            <Input
              type="string"
              ref={register}
              name="company"
              placeholder="Company"
              required={true}
            />
          </StyledFieldset>
          <Button>{buttonLabel}</Button>
        </>
      )}
      {sending && <Content>Please wait as the message is sent...</Content>}
      {status != 200 && status != null && <Content>An error ocurred, please try again.</Content>}
      {status === 200 && (
        <Content>Your message was submitted! We will get back to you as soon as possible.</Content>
      )}
    </StyledForm>
  );
};

export default Form;
