import tw, { styled } from 'twin.macro';

interface OverlayProps {
  visible: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  ${tw`fixed w-full h-full top-0 left-0 z-20 bg-yellow bg-opacity-95`}
  ${({ visible }) => (visible ? tw`block` : tw`hidden`)}

  &:global(#___gatsby) {
    ${tw`overflow-y-hidden`}
  }
`;

export const Wrapper = tw.div`h-full w-full flex flex-col items-start lg:ml-36 pt-24 lg:pt-36`;
