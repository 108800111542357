import React from 'react';

import { Wrapper } from './styled';

interface InputProps {
  type: string;
  placeholder: string;
  name: string;
  required?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, name, placeholder, required = false }, ref) => {
    return (
      <Wrapper type={type} name={name} placeholder={placeholder} ref={ref} required={required} />
    );
  },
);

export default Input;
