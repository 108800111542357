import React from 'react';

import Button from '@components/Button';
import { Overlay, Wrapper } from './styled';
import { Context } from '@components/ContextWrapper';
import Form from '@components/ContactModal/Form';
import Content from '@components/Content';
import Container from '@components/Container';

interface ContactModalProps {
  label: string;
  headingLabel: string;
  buttonLabel: string;
}

const ContactModal: React.FC<ContactModalProps> = ({ label, headingLabel, buttonLabel }) => {
  const { contactModalOpen, toggleContactModal } = React.useContext(Context);
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleContactModal();
  };

  return (
    <>
      <Button onClick={(e) => handleClick(e)}>{label}</Button>
      <Overlay visible={contactModalOpen}>
        <Container>
          <Wrapper>
            <Content>{headingLabel}</Content>
            <Form buttonLabel={buttonLabel} />
          </Wrapper>
        </Container>
      </Overlay>
    </>
  );
};

export default ContactModal;
