import tw, { styled } from 'twin.macro';

interface WrapperProps {
  inView: boolean;
}

export const Wrapper = styled.button<WrapperProps>`
  ${tw`w-fit-content font-sans font-bold relative uppercase tracking-widest text-sm pr-6`}

  &:after {
    ${tw`absolute top-6 left-0 h-3.5 bg-indigo transition-all duration-1000`}
    content: "";
    ${({ inView }) => (inView ? tw`w-full` : tw`w-0`)}}
  }
`;
