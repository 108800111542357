import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { StyledSwiper } from './styled';

SwiperCore.use([Autoplay]);

const Slider: React.FC = ({ children }) => (
  <StyledSwiper
    slidesPerView={2}
    slidesPerGroup={2}
    speed={1000 + Math.round(Math.random() * 1000)}
    effect="flip"
    breakpoints={{
      640: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      768: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
    }}
    autoplay={{
      delay: Math.round(3000 + Math.random() * 1000),
    }}
    loop
  >
    {React.Children.map(children, (child) => (
      <SwiperSlide>{child}</SwiperSlide>
    ))}
  </StyledSwiper>
);

export default Slider;
