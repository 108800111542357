import React from 'react';
import { useCookies } from 'react-cookie';
import { Wrapper, Background, CookieContent } from './styled';
import { Squeeze as Cross } from 'hamburger-react';

interface CookieConsentProps {
  children: React.ReactNode;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ children }) => {
  const [cookies, setCookie] = useCookies(['cookiesAccepted']);
  const toggleCookieConsent = () => {
    setCookie('cookiesAccepted', true, {});
  };

  if (typeof window !== 'undefined' && !cookies.cookiesAccepted)
    return (
      <Background>
        <Wrapper>
          <CookieContent>{children}</CookieContent>
          <Cross
            duration={0.2}
            size={20}
            toggle={toggleCookieConsent}
            color="white"
            toggled={true}
          />
        </Wrapper>
      </Background>
    );

  return null;
};

export default CookieConsent;
