import React from 'react';
import { useInView } from 'react-intersection-observer';

import { Wrapper } from './styled';

export interface ButtonProps {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

const Button: React.FC<ButtonProps> = ({ onClick, children }) => {
  const { ref, inView } = useInView();

  return (
    <Wrapper ref={ref} onClick={onClick} inView={inView}>
      {children}
    </Wrapper>
  );
};

export default Button;
