import 'twin.macro';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { HomepageQuery } from '../../graphql-types';

import Slider from '@components/Slider';
import Layout from '@components/Layout';
import Content from '@components/Content';
import Section from '@components/Section';
import Heading from '@components/Heading';
import ContactModal from '@components/ContactModal/Modal';
import Grid from '@components/Grid';

import { ServiceColumn, SolutionColumn } from '@components/Column';
import CookieConsent from '@components/CookieConsent';

interface IndexPageProps {
  data: HomepageQuery;
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const {
    homepageData: { frontmatter: homepage },
    navigationData: { frontmatter: navigation },
  } = data;

  return (
    <Layout
      title={homepage.title}
      description={homepage.description}
      lang={homepage.lang}
      footer={homepage.footer}
      navigation={navigation}
    >
      <Section color="yellow" id="start">
        <div tw="pt-6 lg:pt-12">
          <Heading color="yellow" backgroundColor="black">
            {homepage.header.heading}
          </Heading>
          <div tw="pt-10">
            <Content>{homepage.header.subheading}</Content>
          </div>
        </div>
        <div tw="pt-24 lg:pt-12 xl:pt-24">
          <ContactModal
            label={homepage.header.cta_label}
            headingLabel={homepage.contact.heading}
            buttonLabel={homepage.contact.button}
          />
        </div>
      </Section>
      {/* Services */}
      <Section color="yellow" id="services">
        <div tw="pb-24 md:pb-36">
          <Heading renderAs="h2" color="yellow" backgroundColor="black">
            {homepage.services.heading}
          </Heading>
          <div tw="pt-6">
            <Content>{homepage.services.subheading}</Content>
          </div>
        </div>
        <Grid>
          {homepage.services.columns.map((col) => (
            <ServiceColumn key={col.title} title={col.title}>
              {col.content}
            </ServiceColumn>
          ))}
        </Grid>
      </Section>
      <Section color="black" tw="pb-12 my-auto" id="clients">
        {/* Clients */}
        <div tw="pb-12 md:pb-24">
          <Heading renderAs="h2" color="black" backgroundColor="white">
            {homepage.clients.heading}
          </Heading>
          <div tw="pt-6">
            <Content>{homepage.clients.subheading}</Content>
          </div>
        </div>
        <div>
          <div tw="pb-6">
            {homepage.clients.sections.map((section) => (
              <div tw="mt-12" key={section.title}>
                <div tw="lg:grid lg:grid-cols-12">
                  <div tw="lg:col-span-2">
                    <Content tw="mt-4">{section.title}</Content>
                  </div>
                  <div tw="col-span-10 hidden lg:grid lg:grid-cols-7 lg:gap-8">
                    {section.logos.map((logo) => {
                      const fluid = getImage(logo.icon);

                      return fluid ? (
                        <GatsbyImage image={fluid} alt={logo.name} key={logo.name} loading="lazy" />
                      ) : null;
                    })}
                  </div>
                </div>
                <div tw="block lg:hidden">
                  <Slider>
                    {section.logos.map((logo) => {
                      const fluid = getImage(logo.icon);

                      return fluid ? (
                        <GatsbyImage image={fluid} alt={logo.name} key={logo.name} loading="lazy" />
                      ) : null;
                    })}
                  </Slider>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
      {/* Solutions */}
      <Section color="white" id="solutions">
        <div tw="pb-24 md:pb-36 full-section:pb-12">
          <Heading renderAs="h2" color="white" backgroundColor="black">
            {homepage.solutions.heading}
          </Heading>
          <div tw="pt-10">
            <Content>{homepage.solutions.subheading}</Content>
          </div>
        </div>
        <Grid>
          {homepage.solutions.columns.map((col) => (
            <SolutionColumn
              key={col.title}
              icon={col.icon.publicURL}
              title={col.title}
              subtitle={col.subtitle}
            >
              {col.content}
            </SolutionColumn>
          ))}
        </Grid>
      </Section>
      <CookieConsent>{homepage.cookie.content}</CookieConsent>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Homepage {
    navigationData: markdownRemark(frontmatter: { content_type: { eq: "navigation" } }) {
      frontmatter {
        links {
          name
          href
        }
        socialLinks {
          name
          href
        }
      }
    }
    homepageData: markdownRemark(frontmatter: { page: { eq: "index" } }) {
      frontmatter {
        title
        description
        lang
        solutions {
          heading
          subheading
          columns {
            icon {
              publicURL
            }
            subtitle
            content
            title
          }
        }
        services {
          subheading
          heading
          columns {
            title
            content
          }
        }
        header {
          subheading
          heading
          cta_label
        }
        contact {
          heading
          button
        }
        cookie {
          content
        }
        footer {
          heading
          content
          columns {
            name
            content
            featured_contact
            contact_highlight
            logos {
              name
              icon {
                childImageSharp {
                  gatsbyImageData(width: 185, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
        clients {
          heading
          subheading
          sections {
            title
            logos {
              name
              icon {
                childImageSharp {
                  gatsbyImageData(width: 150, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
        certificates {
          heading
          subheading
          content {
            name
          }
        }
      }
    }
  }
`;
